import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { Download, ArrowLeft, Maximize, Minimize } from 'lucide-react';

const ViewFile = ({ user }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const uri = url.searchParams.get('url');

        if (!uri) {
          throw new Error('No file URL provided');
        }

        // Extract filename from URL
        const name = decodeURIComponent(uri.split('/').pop());
        setFileName(name);

        // Test file accessibility
        const response = await fetch(uri);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Check content type for additional validation
        const contentType = response.headers.get('content-type');
        if (!isValidContentType(contentType)) {
          throw new Error('Unsupported file type');
        }

        setFileUrl(uri);
      } catch (err) {
        console.error('Error accessing file:', err);
        setError(getErrorMessage(err));
      } finally {
        setLoading(false);
      }
    };

    fetchFile();
  }, []);

  const isValidContentType = (contentType) => {
    const supportedTypes = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    return !contentType || supportedTypes.some(type => contentType.includes(type));
  };

  const getErrorMessage = (error) => {
    if (error.message.includes('status: 403')) {
      return 'Access denied. You may not have permission to view this file.';
    }
    if (error.message.includes('status: 404')) {
      return 'File not found. The file may have been moved or deleted.';
    }
    if (error.message.includes('Unsupported file type')) {
      return 'This file type is not supported for preview.';
    }
    return 'Unable to access file. Please check if the URL is correct and accessible.';
  };

  const handleDownload = () => {
    if (fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="bg-white p-6 rounded-lg shadow-sm max-w-md w-full">
          <p className="text-gray-600 text-center">Please log in to view files.</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="flex flex-col items-center gap-4">
          <div className="w-8 h-8 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
          <p className="text-gray-600">Loading file...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="bg-white p-6 rounded-lg shadow-sm max-w-md w-full">
          <div className="flex items-center gap-2 text-red-600 mb-4">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <h2 className="text-xl font-semibold">Error Loading File</h2>
          </div>
          <p className="text-gray-600 mb-6">{error}</p>
          <div className="flex gap-4">
            <button 
              onClick={() => window.history.back()} 
              className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
            >
              <ArrowLeft className="w-4 h-4" />
              Go Back
            </button>
            <button 
              onClick={() => window.location.reload()} 
              className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-md transition-colors"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-gray-50 ${isFullscreen ? 'fixed inset-0 z-50' : 'min-h-screen'}`}>
      {fileUrl && (
        <div className="h-screen flex flex-col">
          {/* Header */}
          <div className="bg-white border-b px-4 py-2 flex items-center justify-between">
            <h1 className="text-lg font-semibold truncate max-w-2xl">{fileName}</h1>
            <div className="flex items-center gap-2">
              <button
                onClick={handleDownload}
                className="flex items-center gap-2 px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
              >
                <Download className="w-4 h-4" />
                Download
              </button>
              <button
                onClick={toggleFullscreen}
                className="p-2 hover:bg-gray-100 rounded-md transition-colors"
              >
                {isFullscreen ? (
                  <Minimize className="w-4 h-4" />
                ) : (
                  <Maximize className="w-4 h-4" />
                )}
              </button>
            </div>
          </div>

          {/* Document Viewer */}
          <div className="flex-1 bg-white">
            <DocViewer
              documents={[{ uri: fileUrl, fileName: fileName }]}
              pluginRenderers={DocViewerRenderers}
              style={{ height: '100%' }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                },
                pdfZoom: {
                  defaultZoom: 1.1,
                  zoomJump: 0.2,
                },
                pdfVerticalScrollByDefault: true,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewFile;