import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { doc, getDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../config/firebaseConfig'; // Firebase configuration
import { FiDownload, FiEye } from 'react-icons/fi';
import { useParams } from 'wouter';
import Loading from '../components/Loading';
import { findUserByEmail, getLastThreeMonths, getLastTwoYears, industryCategories, isAdmin, sendStatusChangeEmail } from '../utils/helpers';

const validStyle = { outline: '2px solid #078586' };
const invalidStyle = { outline: '2px solid red' };

const initialStyles = {
  businessName: {},
  dba: {},
  referrer: {},
  referrerEmail: {},
  useOfFunds: {},
  address: {},
  city: {},
  state: {},
  zip: {},
  tel: {},
  dateIncorporated: {},
  industry: {},
  businessDescription: {},
  ownerName: {},
  ownerTel: {},
  ownerEmail: {},
  ownerDOB: {},
  ownerSSN: {},
  pctOwnership: {},
  driversLicenseFront: {},
  driversLicenseBack: {},
  bankStatement1: {},
  bankStatement2: {},
  bankStatement3: {},
  businessApplication: {},
};

const DealDetailPage = ({ user, userInfo }) => {
  const { dealId } = useParams();
  const [deal, setDeal] = useState(null);
  const [dealStatus, setDealStatus] = useState('');
  const [additionalFiles, setAdditionalFiles] = useState({
    bankStatementsAdditional: [],
    applicationsAdditional: [],
    taxReturnsAdditional: [],
    miscellaneousAdditional: []
  });
  const [loading, setLoading] = useState(false);
  const [submitStatusMessage, setSubmitStatusMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editableFields, setEditableFields] = useState({
    referrer: '',
    referrerEmail: '',
    useOfFunds: '',
    businessName: '',
    dba: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    tel: '',
    dateIncorporated: '',
    industry: '',
    businessDescription: '',
    ownerName: '',
    ownerTel: '',
    ownerEmail: '',
    ownerDOB: '',
    ownerSSN: '',
    pctOwnership: '',
    driversLicenseFront: '',
    hasLargeLenderBalances: '',
    bankStatement1: '',
    bankStatement2: '',
    bankStatement3: '',
    businessApplication: '',
  });

 
  const [hasChanged, setHasChanged] = useState(false);


  const [formData, setFormData] = useState({
    referrer: '',
    referrerEmail: '',
    useOfFunds: '',
    businessName: '',
    dba: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    tel: '',
    dateIncorporated: '',
    industry: '',
    businessDescription: '',
    ownerName: '',
    ownerTel: '',
    ownerEmail: '',
    ownerDOB: '',
    ownerSSN: '',
    pctOwnership: '',
    driversLicenseFront: '',
    hasLargeLenderBalances: '',
    bankStatement1: '',
    bankStatement2: '',
    bankStatement3: '',
    businessApplication: '',
  });

  const [message, setMessage] = useState('');
  const [messageSubmitStatus, setMessageSubmitStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [dealUser, setDealUser] = useState({})

  const [selectedStatus, setSelectedStatus] = useState(dealStatus);
  const [statusChanged, setStatusChanged] = useState(false);

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    setStatusChanged(newStatus !== dealStatus);
  };

  const updateDealStatus = async () => {
    setLoading(true);
    
    try {
      // Update status in Firestore
      await setDoc(doc(db, 'deals', dealId), { 
        status: selectedStatus 
      }, { merge: true });
  
      // Get status message based on new status
      const statusMessage = getStatusMessage(selectedStatus, deal.businessName);
  
      // Create message object for ISO notification
      const messageObj = {
        dealId: dealId,
        message: statusMessage,
        timestamp: new Date().toISOString(),
        from: 'system',
        dealName: deal.businessName,
        status: selectedStatus,
        type: 'status_update'
      };
  
      // Update deal with status message
      await setDoc(doc(db, 'deals', dealId), {
        brokerMessages: arrayUnion(messageObj),
        lastMessageTimestamp: new Date().toISOString()
      }, { merge: true });
  
      // Also update ISO's messages collection
      if (deal.referrerEmail && dealUser?.uid) {
        await setDoc(doc(db, 'users', dealUser.uid), {
          messages: arrayUnion(messageObj)
        }, { merge: true });
      }

      await sendStatusChangeEmail(deal.referrer, deal.referrerEmail, selectedStatus, deal.businessName )
      
      setDealStatus(selectedStatus);
      setStatusChanged(false);
      setSubmitStatusMessage('Status and notifications updated successfully.');
      setLoading(false);
    } catch (error) {
      console.error('Error updating status:', error);
      setSubmitStatusMessage('Error updating status and notifications');
      setLoading(false);
    }
  };
  
  // Helper function to get status message
  const getStatusMessage = (status, merchantName) => {
    const statusMessages = {
      'Pending': `New Submission - ${merchantName}\nStatus Description: New deal submission received, pending entry completion.`,
      
      'In Progress': `IN PROGRESS: Credit Review - ${merchantName}\nStatus Description: Deal is entered; Credit and statements are being reviewed for pricing.`,
      
      'Under Review': `UNDER REVIEW: Full Analysis - ${merchantName}\nStatus Description: Application is undergoing comprehensive underwriting review.`,
      
      'Declined': `DECLINED: Decision Notice - ${merchantName}\nStatus Description: After careful review of the submitted application and supporting documentation, we are unable to approve this request at this time. A detailed explanation and any alternative options will be provided in the accompanying communication.`,
      
      'Closing Docs Out': `CLOSING DOCS: Ready for Review - ${merchantName}\nStatus Description: An offer has been accepted and the contracts are now available to review or to be sent for e-signature.`,
      
      'Closing Docs Signed': `DOCS SIGNED: Under Review - ${merchantName}\nStatus Description: Contracts have been signed and the application is being reviewed by one of our internal underwriters.`,
      
      'Approved': `APPROVED: Ready to Fund - ${merchantName}\nStatus Description: The deal is fully approved without any funding stipulations.`,
      
      'Funded': `FUNDED: Wire Sent - ${merchantName}\nStatus Description: The proceeds for the client have been sent via wire transfer. Those wire details will be provided in a separate email. If this funding required payoffs, those have been processed as well.`
    };
  
    return statusMessages[status] || `Status Update: ${status} - ${merchantName}`;
  };

// 3. Add this new function for handling messages
const handleSendMessage = async () => {
  if (!message.trim()) {
    alert('Please enter a message');
    return;
  }

  setIsSubmitting(true);
  setMessageSubmitStatus('');
  
  try {
    const messageObj = {
      dealId: dealId,
      message: `Question for Broker: BROKER ACTION: Response Required - ${message}`,
      timestamp: new Date().toISOString(),
      from: user.email,
      dealName: deal.businessName,
      status: 'Question for Broker'
    };

    await setDoc(doc(db, 'deals', dealId), {
      brokerMessages: arrayUnion(messageObj),
      lastMessageTimestamp: new Date().toISOString()
    }, { merge: true });

    // Also update user's messages
    await setDoc(doc(db, 'users', dealUser.uid), {
      messages: arrayUnion(messageObj)
    }, { merge: true });

    setMessage(''); // Clear input
    setMessageSubmitStatus('Message sent successfully!');
    
    // Clear success message after 3 seconds
    setTimeout(() => {
      setMessageSubmitStatus('');
    }, 3000);

  } catch (error) {
    console.error('Error sending message:', error);
    setMessageSubmitStatus('Error sending message');
  } finally {
    setIsSubmitting(false);
  }
};

  const fetchDeal = async () => {
    setLoading(true);
    const dealDoc = await getDoc(doc(db, 'deals', dealId));
    if (dealDoc.exists()) {
      const dealData = dealDoc.data();
      setDeal(dealData);
      setEditableFields(dealData);
      setDealStatus(dealData.status);

      // Set up additional files from Firestore data (if present)
      setAdditionalFiles({
        bankStatementsAdditional: dealData.bankStatementsAdditional || [],
        applicationsAdditional: dealData.applicationsAdditional || [],
        taxReturnsAdditional: dealData.taxReturnsAdditional || [],
        miscellaneousAdditional: dealData.miscellaneousAdditional || [],
      });

      setFormData({
        ...dealData,
        bankStatementsAdditional: dealData.bankStatementsAdditional || [],
        applicationsAdditional: dealData.applicationsAdditional || [],
        taxReturnsAdditional: dealData.taxReturnsAdditional || [],
        miscellaneousAdditional: dealData.miscellaneousAdditional || [],
      });
      setLoading(false);
    } else {
      setLoading(false);
      console.error('Deal not found');
    }
  };

  useEffect(()=> {
    fetchDeal();
    // eslint-disable-next-line
  }, [])

  useEffect(()=> {
    if(deal?.userEmail) {
      findUserByEmail(deal.userEmail)
      .then((user) => {
        setDealUser(user)
      })
    }
    // eslint-disable-next-line
  }, [deal])

  const handleFocus = (e) => {
    const { name } = e.target;
    let updatedStyles = { ...initialStyles };
  
    if (name === 'referrerEmail' || name === 'ownerEmail') {
      updatedStyles[name] = validateEmail(formData?.[name]) ? validStyle : invalidStyle;
    }
  
    setFieldStyles(prevStyles => ({
      ...prevStyles,
    }));
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!file || !(file instanceof Blob)) {
        // If the file is not provided or not a Blob, return 'N/A'
        resolve('N/A');
        return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateField = (name, value) => {
    let isValid = false;
  
    // Handle undefined values or file inputs
    if (typeof value === 'string') {
      value = value.trim();
    }
  
    switch (name) {
      case 'referrerEmail':
      case 'ownerEmail':
        isValid = validateEmail(value);
        break;
      case 'zip':
        isValid = value?.length === 5;
        break;
      case 'tel':
      case 'ownerTel':
        isValid = value?.length === 10;
        break;
      case 'ownerSSN':
        isValid = value?.length === 9 && parseInt(value);
        break;
      case 'businessName':
      case 'referrer':
      case 'useOfFunds':
      case 'address':
      case 'city':
      case 'state':
      case 'dateIncorporated':
      case 'industry':
      case 'businessDescription':
      case 'ownerName':
      case 'ownerDOB':
      case 'pctOwnership':
        isValid = typeof value === 'string' && value?.trim() !== '';
        break;
      case 'driversLicenseFront':
      case 'bankStatement1':
      case 'bankStatement2':
      case 'bankStatement3':
      case 'businessApplication':
        isValid = !!value;
        break;
      default:
        // eslint-disable-next-line
        isValid = true;
    }
  };

  const [fieldStyles, setFieldStyles] = useState(initialStyles);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditableFields({ ...editableFields, [name]: value });
  };

  const saveEdits = async () => {
    try {
      setLoading(true)
      await setDoc(doc(db, 'deals', dealId), editableFields, { merge: true });
      setDeal(editableFields);
      setSubmitStatusMessage('Information updated successfully.');
      setIsEditing(false);
      setLoading(false)
    } catch (error) {
      console.error('Error saving edits:', error);
    }
  };

  const renderDocumentRow = (label, fileKey, categoryName, isMandatory = false) => {
    const fileUrl = deal?.[fileKey] ? deal?.[fileKey] : null;

    const handleViewClick = (e) => {
      e.preventDefault();
      alert('Your file has been downloaded and is ready to view. Please use a PDF viewer.');
      window.open(fileUrl, '_blank');
    };
  
    // Check if this fileKey corresponds to an additional files category
    const isAdditionalCategory = [
      'bankStatementsAdditional',
      'applicationsAdditional',
      'taxReturnsAdditional',
      'miscellaneousAdditional'
    ].includes(fileKey);

    return (
      <div className="flex flex-col md:lg:flex-row md:lg:items-center justify-between mb-4 w-full">
        <span className="font-bold mb-2 md:lg:mb-0">{label}{isMandatory && '*'}</span>
        <div className="flex flex-col space-x-4 items-center w-full max-w-[500px]">
          {fileUrl && !isAdditionalCategory ? (
            <div className='w-full flex flex-col ml-auto md:flex-row lg:flex-row gap-6'>
              <div className='ml-auto flex gap-2 w-full'>
                <a href={fileUrl} onClick={handleViewClick} target="_blank" rel="noopener noreferrer" className="w-[210px] cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition">
                  <FiEye className="mr-1" /> View
                </a>
                <a href={fileUrl} download={deal?.[fileKey]?.name} className="w-full cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition">
                  <FiDownload className="mr-1" /> Download
                </a>
              </div>
              <input
                id={`${fileKey}`}
                type="file"
                className="hidden"
                name={fileKey}
                onChange={(e) => handleFileUpload(e, fileKey)}
                onFocus={handleFocus}
              />
            </div>
          ) : (
            (
              <div className="w-full flex items-center">
                <CustomDragAndDrop fileName={fileKey} categoryName={categoryName} />
              </div>
            )
          )}
        </div>
      </div>
    );
  };


  const renderAdditionalFiles = (files, category, categoryName) => {
    if (!files || files.length === 0) {
      return 'No additional files uploaded';
    }

    const handleViewClick = (e, fileUrl) => {
      e.preventDefault();
      alert('Your file has been downloaded and is ready to view. Please use a PDF viewer.');
      window.open(fileUrl, '_blank');
    };
  
    return (
      <div className="flex flex-col">
        {files.map((file, index) => (
          <div key={index} className="flex flex-col md:lg:flex-row md:lg:items-center justify-between mb-2">
            <span className="font-bold mb-2 md:lg:mb-0">{categoryName} File {index+1}</span>
            <div className='flex self-right gap-2 w-full max-w-[500px]'>
              <a
                href={file}
                onClick={(e) => handleViewClick(e, file)}
                target="_blank"
                rel="noopener noreferrer"
                className="w-[210px] cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition"
              >
                <FiEye className="mr-1" /> View
              </a>
              <a
                href={file}
                download={file}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition"
              >
                <FiDownload className="mr-1" /> Download
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const CustomDragAndDrop = ({ fileName, categoryName }) => {
    const [isDragging, setIsDragging] = useState(false);
  
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = () => {
      setIsDragging(false);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      const object = { target: { files: e.dataTransfer.files, id: fileName, value: '' } };
      handleFileUpload(object); // Trigger file upload on drop
    };
  
    const handleFileChange = (e) => {
      handleFileUpload(e); // Trigger file upload on file change
    };
  
    // Determine styles based on dragging state
    const getBorderStyle = () => {
      return isDragging ? 'border-blue-400 bg-blue-50' : 'border-gray-300';
    };
  
    return (
      <div className="flex items-center gap-2 mb-4 w-fit md:lg:w-full ml-auto">
        {/* + Add Button */}
        <div>
          <label
            htmlFor={`${fileName}`}
            className="w-full md:lg:w-[150px] cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition"
          >
            + Add {fileName.includes("Additional") ? `${categoryName} Files` : ""}
          </label>
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id={`${fileName}`}
          />
        </div>
        {/* Drag and Drop Area */}
        <div
          className={`hidden md:flex lg:flex items-center justify-center border border-dashed ${getBorderStyle()} p-2 rounded-md w-full transition-colors duration-200 cursor-pointer`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <p className="text-sm text-gray-500">
            Drag & drop file here
          </p>
        </div>
  
      </div>
    );
  };

  const handleFileUpload = (e) => {
    const { id: name, value, files } = e.target;

    const data = files ? { ...formData, [name]: files[0] } : { ...formData, [name]: value }

    if (!hasChanged) {
      setHasChanged(true);
    }
  
    if (files) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  
    validateField(name, value || files?.[0]?.name); // Run validation on change

    handleSubmit(data)
  };

  const handleSubmit = async (formData) => {
    try {
        setLoading(true);
        setSubmitStatusMessage('Submitting records...');

        // Get the last three months and two years
        const lastThreeMonths = getLastThreeMonths();
        const lastTwoYears = getLastTwoYears();

        // Utility function to check if a field is a file object
        const isFileObject = (file) => file instanceof File;

        // Convert files to base64 dynamically
        const convertFilesToBase64 = async (data) => {
          const convertedData = { ...data };

          // Convert standard fields
          const fieldsToConvert = [
              'driversLicenseFront',
              'driversLicenseBack',
              'businessApplication',
              'microLoanApplication',
              'quidityLibriumSBA',
              'quidityJumboLoan',
              'bankStatementsAdditional',
              'applicationsAdditional',
              'taxReturnsAdditional',
              'miscellaneousAdditional',
              `businessTaxReturn2021`,
              `personalTaxReturn2021`,
              `businessTaxExtension${new Date().getFullYear()-1}`,
              `personalTaxExtension${new Date().getFullYear()-1}`,
              `plStatement${new Date().getFullYear()-1}`,
              `accountsReceivable${new Date().getFullYear()-1}`,
              `accountsPayables${new Date().getFullYear()-1}`,
          ];

          for (const field of fieldsToConvert) {
              if (isFileObject(data[field])) {
                  convertedData[field] = {
                      name: field,
                      content: await fileToBase64(data[field]),
                      type: data[field]?.type,
                  };
              }
          }

          // Convert dynamic month-based bank statements
          for (const { monthName, year } of lastThreeMonths) {
              const key = `bankStatement${monthName}${year}`;
              if (isFileObject(data[key])) {
                  convertedData[key] = {
                      name: key,
                      content: await fileToBase64(data[key]),
                      type: data[key]?.type,
                  };
              }
          }

          // Convert dynamic year-based tax returns
          for (const year of lastTwoYears) {
              const businessTaxKey = `taxReturn${year}`;
              const personalTaxKey = `personalTaxReturn${year}`;
              if (isFileObject(data[businessTaxKey])) {
                  convertedData[businessTaxKey] = {
                      name: businessTaxKey,
                      content: await fileToBase64(data[businessTaxKey]),
                      type: data[businessTaxKey]?.type,
                  };
              }
              
              if (isFileObject(data[personalTaxKey])) {
                  convertedData[personalTaxKey] = {
                      name: personalTaxKey,
                      content: await fileToBase64(data[personalTaxKey]),
                      type: data[personalTaxKey]?.type,
                  };
              }
          }

          return convertedData;
        };

        // Convert files to base64
        const formDataObject = await convertFilesToBase64(formData);

        // Prepare modified fields by checking each field against the existing deal
        const modifiedFields = {};
        const fieldsToCheck = [
            'referrer', 'referrerEmail', 'useOfFunds', 'businessName', 'dba', 'address',
            'city', 'state', 'zip', 'tel', 'dateIncorporated', 'industry', 'businessDescription',
            'ownerName', 'ownerTel', 'ownerEmail', 'ownerDOB', 'ownerSSN', 'pctOwnership',
            'hasLargeLenderBalances'
        ];

        fieldsToCheck.forEach(field => {
            if (formDataObject[field] !== deal?.[field]) {
                modifiedFields[field] = formDataObject[field];
            }
        });

        // Add converted files and dynamically generated fields (like bank statements and tax returns)
        Object.assign(modifiedFields, formDataObject);

        // // Send only modified fields to the backend
        const response = await axios.post('https://us-central1-quidity-app.cloudfunctions.net/modifyDeal', {
            formData: formDataObject,
            userId: dealUser.uid,
            userEmail: dealUser.email,
            docId: dealId,
            userInfo,
        });

        setSubmitStatusMessage(response.data.message);

        // // Fetch the updated deal after submission
        await fetchDeal();

        setLoading(false);
    } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitStatusMessage('Error submitting form');
        setLoading(false);
    }
};

  // Usage of the helper functions
  const lastThreeMonths = getLastThreeMonths();
  const lastTwoYears = getLastTwoYears();
  
  if(loading || deal === null) {
    return <div className="h-screen"><Loading /></div>;
  }

  return (
    <div className="container mx-auto py-8 w-[95%] md:w-[70%] lg:w-[70%] mt-[130px]">
      <h2 className="text-3xl font-bold mb-4">{isEditing ? (
        <input
          type="text"
          name="businessName"
          value={editableFields?.businessName || deal.businessName}
          onChange={handleFieldChange}
          className="border p-2 rounded-md"
        />
      ) : (
        'Business Name: ' + deal?.businessName
      )}
      </h2>
       {/* Status Change */}
       { isAdmin(user) &&
          <section className="mb-8 flex gap-2 items-center">
            <h3 className="text-xl font-bold">Deal Status:</h3>
            <select 
              value={selectedStatus} 
              onChange={handleStatusChange} 
              className="p-2 border rounded-md cursor-pointer"
            >
              <option value="Pending">Pending</option>
              <option value="In Progress">In Progress</option>
              <option value="Under Review">Under Review</option>
              <option value="Question for Broker">Question for Broker</option>
              <option value="Declined">Declined</option>
              <option value="Closing Docs Out">Closing Docs Out</option>
              <option value="Closing Docs Signed">Closing Docs Signed</option>
              <option value="Approved">Approved</option>
              <option value="Funded">Funded</option>
            </select>
            <button
              onClick={updateDealStatus}
              disabled={!statusChanged}
              className="px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50"
            >
              Update Status
            </button>
          </section>
      }
      {selectedStatus === 'Question for Broker' && isAdmin(user) && (
        <div className='flex flex-col gap-2 mb-8'>
          <div className='flex items-center gap-4'>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Please type your message"
              className="themed-input-edit flex-1"
              disabled={isSubmitting}
            />
            <button
              onClick={handleSendMessage}
              disabled={isSubmitting}
              className="px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50"
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
          {messageSubmitStatus && (
            <div className={`text-sm p-4 rounded-md ${
              messageSubmitStatus.includes('Error') 
                ? 'bg-red-100 border border-red-600 text-red-600 font-semibold' 
                : 'bg-green-100 border border-green-800 text-green-800 font-semibold'
            }`}>
              {messageSubmitStatus}
            </div>
          )}
        </div>
      )}
      <div className='mb-4 flex flex-col md:lg:flex-row w-full justify-between gap-2 md:lg:p-4 md:lg:border md:lg:border-themeColorPrimary rounded'>
        {/* Business Information */}
        <section className="mb-4 flex justify-start w-full">
          <div className='flex flex-col items-start'>

          <h2 className="text-lg underline font-bold mb-2">Business Information:</h2>
          <p><b>Referrer Name:</b> {isEditing ? (
            <input
              type="text"
              name="referrer"
              value={editableFields.referrer || deal.referrer}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.referrer}</p>
          <p><b>Referrer Email:</b> {isEditing ? (
            <input
              type="email"
              name="referrerEmail"
              value={editableFields.referrerEmail || deal.referrerEmail}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.referrerEmail}</p>
          <p><b>Industry:</b> {isEditing ? (
            <select
              style={fieldStyles.industry}
              onFocus={handleFocus}
              id="industry"
              name="industry"
              value={editableFields.industry || deal.industry}
              onChange={handleFieldChange}
              className="themed-input-edit cursor-pointer"
              required
            >
              <option value="">Select Industry</option>
              {Object.keys(industryCategories).map((ind) => (
                <option key={ind} value={ind}>{ind}</option>
              ))}
            </select>
          ) : deal.industry}</p>
          <p><b>Description:</b> {isEditing ? (
            <input
              type="text"
              name="businessDescription"
              value={editableFields.businessDescription || deal.businessDescription}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.businessDescription}</p>
          <p><b>Address:</b> {isEditing ? (
            <input
              type="text"
              name="address"
              value={editableFields.address || deal.address}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : `${deal.address}, ${deal.city}, ${deal.state} ${deal.zip}`}</p>
          <p><b>Incorporated on:</b> {isEditing ? (
            <input
              type="date"
              name="dateIncorporated"
              value={editableFields.dateIncorporated || deal.dateIncorporated}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.dateIncorporated}</p>
          <p><b>MCA{'>'}$50k:</b> {isEditing ? (
            <input
              type="text"
              name="hasLargeLenderBalances"
              value={editableFields.hasLargeLenderBalances || deal.hasLargeLenderBalances}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.hasLargeLenderBalances}</p>
          <p><b>Use of funds:</b> {isEditing ? (
            <input
              type="text"
              name="useOfFunds"
              value={editableFields.useOfFunds || deal.useOfFunds || 'N/A'}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.useOfFunds || 'N/A'}</p>
          </div>
        </section>

        {/* Owner Information */}
        <section className="mb-4 flex justify-start w-full">
          <div className='flex flex-col items-start'>

          <h3 className="text-lg underline font-bold mb-2">Owner Information</h3>
          <p><b>Owner:</b> {isEditing ? (
            <input
              type="text"
              name="ownerName"
              value={editableFields.ownerName || deal.ownerName}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.ownerName}</p>
          <p><b>Email:</b> {isEditing ? (
            <input
              type="email"
              name="ownerEmail"
              value={editableFields.ownerEmail || deal.ownerEmail}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.ownerEmail}</p>
          <p><b>Date of Birth:</b> {isEditing ? (
            <input
              type="date"
              name="ownerDOB"
              value={editableFields.ownerDOB || deal.ownerDOB}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.ownerDOB}</p>
          <p><b>SSN:</b> {isEditing ? (
            <input
              type="text"
              name="ownerSSN"
              value={editableFields.ownerSSN || deal.ownerSSN}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.ownerSSN}</p>
          <p><b>Telephone:</b> {isEditing ? (
            <input
              type="tel"
              name="ownerTel"
              value={editableFields.ownerTel || deal.ownerTel}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : deal.ownerTel}</p>
          <p><b>Ownership:</b> {isEditing ? (
            <input
              type="number"
              name="pctOwnership"
              value={editableFields.pctOwnership || deal.pctOwnership}
              onChange={handleFieldChange}
              className="themed-input-edit"
            />
          ) : `${deal.pctOwnership}%`}</p>
          </div>
        </section>
          {/* Edit Button */}
          {
            isAdmin(user) &&
            <button
              className="h-fit mt-auto mb-4 p-2 px-8 bg-themeColorPrimary text-white rounded-md"
              onClick={isEditing ? saveEdits : handleEditToggle}
            >
              {isEditing ? 'Save' : 'MODIFY'}
            </button>
          }
      </div>

      {/* Documents Section */}
      <section className="mb-8">
        {/* <h3 className="text-xl font-bold mb-4 underline">Documents</h3> */}

        {/* Bank Statements */}
        <h4 className="text-lg font-bold mb-2 underline">Bank Statements</h4>
        {lastThreeMonths.map((monthData, index) => (
          renderDocumentRow(`Bank Statement ${monthData.monthName} ${monthData.year}`, `bankStatement${monthData.monthName}${monthData.year}`, true)
        ))}
        {/* Additional File Upload Sections */}
        {renderAdditionalFiles(additionalFiles?.bankStatementsAdditional || [], 'bankStatementsAdditional', 'Bank')}
        {renderDocumentRow('', 'bankStatementsAdditional', 'Bank')}

        {/* Applications */}
        <h4 className="text-lg font-bold mt-8 mb-2 underline">Applications</h4>
        {renderDocumentRow('Business Application', 'businessApplication', true)}
        {renderDocumentRow('Quidity SBA Loan', 'quidityLibriumSBA')}
        {renderAdditionalFiles(additionalFiles?.applicationsAdditional || [], 'applicationsAdditional', 'App')}
        {renderDocumentRow('', 'applicationsAdditional', 'App')}

       {/* Tax Documents */}
        <h3 className="text-lg underline font-bold mb-4">Tax Returns:</h3>

        {/* Business Tax Returns */}
        <h4 className="text-md font-bold mb-2 underline">Business Tax Returns</h4>
        {lastTwoYears.map((year) => (
          renderDocumentRow(`Business Tax Return ${year}`, `taxReturn${year}`, true)
        ))}
        {renderDocumentRow('Business Tax Return 2021', 'businessTaxReturn2021')}
        {renderAdditionalFiles(additionalFiles?.taxReturnsAdditional || [], 'taxReturnsAdditional', 'Tax')}
        {renderDocumentRow('', 'taxReturnsAdditional', 'Tax')}

        {/* Personal Tax Returns */}
        <h4 className="text-md font-bold mt-6 mb-2 underline">Personal Tax Returns</h4>
        {lastTwoYears.map((year) => (
          renderDocumentRow(`Personal Tax Return ${year}`, `personalTaxReturn${year}`, true)
        ))}
        {renderDocumentRow('Personal Tax Return 2021', 'personalTaxReturn2021')}
        {renderAdditionalFiles(additionalFiles?.taxReturnsAdditional || [], 'taxReturnsAdditional', 'Tax')}
        {renderDocumentRow('', 'taxReturnsAdditional', 'Tax')}

        {/* Tax Extensions */}
        <h4 className="text-md font-bold mt-6 mb-2 underline">Tax Extensions</h4>
        {renderDocumentRow(
          `Business Tax Extension ${new Date().getFullYear()-1}`, 
          `businessTaxExtension${new Date().getFullYear()-1}`
        )}
        {renderDocumentRow(
          `Personal Tax Extension ${new Date().getFullYear()-1}`, 
          `personalTaxExtension${new Date().getFullYear()-1}`
        )}

        {/* Miscellaneous Documents */}
        <h4 className="text-lg font-bold mt-8 mb-2 underline">Miscellaneous Documents</h4>
        {renderDocumentRow("Driver's License Front", 'driversLicenseFront', true)}
        {renderDocumentRow(`P & L Statement ${new Date().getFullYear()-1}`, `plStatement${new Date().getFullYear()-1}`)}
        {renderDocumentRow(`Accounts Receivable ${new Date().getFullYear()-1}`, `accountsReceivable${new Date().getFullYear()-1}`)}
        {renderDocumentRow(`Accounts Payables ${new Date().getFullYear()-1}`, `accountsPayables${new Date().getFullYear()-1}`)}

        {renderAdditionalFiles(additionalFiles?.miscellaneousAdditional || [], 'miscellaneousAdditional', 'Misc')}
        {renderDocumentRow('', 'miscellaneousAdditional', 'Misc')}
      </section>
      {submitStatusMessage && <p className="w-fit mt-4 text-white font-bold bg-green-500 p-2 px-4 rounded">{submitStatusMessage}</p>}
    </div>
  );
};

export default DealDetailPage;
