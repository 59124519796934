import React, { useState, useRef, useEffect } from 'react';
import Logo from '../assets/logo.png';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { useLocation } from 'wouter';
import { isAdmin } from '../utils/helpers';
import { MessageSquare, X } from 'lucide-react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import Loading from './Loading';

const formatTimestamp = (timestamp) => {
  try {
    const date = new Date(timestamp);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  } catch (error) {
    return 'Invalid date';
  }
};

const Header = ({ user, userInfo }) => {
  const [location, navigate] = useLocation();
  const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('unread');
  const [isUpdating, setIsUpdating] = useState(false);
  const messagePopupRef = useRef(null);
  const messageButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        messagePopupRef.current && 
        !messagePopupRef.current.contains(event.target) &&
        !messageButtonRef.current.contains(event.target)
      ) {
        setIsMessagePopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCreateDeal = () => {
    navigate('/create-deal');
  };

  const handlePortal = () => {
    navigate('/portal');
  };

  const handleLogout = () => {
    signOut(auth);
    navigate('/')
  };

  const handleDealClick = async (message) => {
    if (isUpdating) return; // Prevent double-clicks
    setIsUpdating(true);

    try {
      // Get current user doc
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        // Update the message's read status in the messages array
        const updatedMessages = userDoc.data().messages.map(msg => {
          if (msg.dealId === message.dealId && msg.timestamp === message.timestamp) {
            return { ...msg, read: true };
          }
          return msg;
        });

        // Update Firestore
        await updateDoc(userRef, {
          messages: updatedMessages
        });

        // Navigate to the deal
        navigate(`/deal/modify/${message.dealId}`);
        window.location.reload()
        setIsMessagePopupOpen(false);
      }
    } catch (error) {
      console.error('Error updating message status:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Split messages into read and unread
  const messages = userInfo?.messages || [];
  const unreadMessages = messages.filter(msg => !msg.read);
  const readMessages = messages.filter(msg => msg.read);

  const MessageList = ({ messages }) => (
    <div className="overflow-y-auto max-h-[320px]">
      {messages.length > 0 ? (
        messages
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map((msg, index) => (
            <div 
              key={index}
              className={`p-3 border-b border-gray-100 hover:bg-gray-50 cursor-pointer ${
                isUpdating ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={() => handleDealClick(msg)}
            >
              <div className="flex justify-between items-start mb-1">
                <span className="font-medium text-sm text-gray-900">{msg.dealName}</span>
                <span className="text-xs text-gray-500">
                  {formatTimestamp(msg.timestamp)}
                </span>
              </div>
              <p className="text-sm text-gray-600">{msg.message}</p>
            </div>
          ))
      ) : (
        <div className="p-4 text-center text-gray-500">No messages</div>
      )}
    </div>
  );

  if(!user && window.location.pathname !== '/') {
    return <div className="h-screen"><Loading /></div>
  }

  return (
    <>
      {userInfo && !isAdmin(user) && (
        <div className="fixed mb-4 w-full h-[35px] bg-themeColorPrimary px-[50px] flex items-center">
          <p className="w-fit ml-auto text-white font-semibold">
            Hello, <b>{`${userInfo.firstName} ${userInfo.lastName}` || 'User'}</b>{' '}
            {`(${userInfo.companyName.toUpperCase()})`}
          </p>
        </div>
      )}
      <div
        style={{ top: userInfo && !isAdmin(user) ? 35 : 0 }}
        className="fixed px-8 py-4 bg-white w-full flex flex-col md:flex-row lg:flex-row z-[999] shadow-sm"
      >
        <a href="/" className="w-fit">
          <img className="h-[60px] md:h-[65px] lg:h-[65px]" src={Logo} alt="logo" />
        </a>
        {(location === '/portal' || location === '/create-deal' || location.startsWith('/deal/modify')) && !isAdmin(user) &&  (
          <div className="relative mt-4 md:mt-0 lg:mt-0 flex md:ml-auto lg:ml-auto items-center md:items-center ld:items-center flex-col md:flex-row lg:flex-row">
            <div className="relative">
              <div 
                ref={messageButtonRef}
                className="flex items-center cursor-pointer" 
                onClick={() => setIsMessagePopupOpen(!isMessagePopupOpen)}
              >
                {unreadMessages.length > 0 && (
                  <span className="z-[999] absolute -top-2 right-5 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {unreadMessages.length}
                  </span>
                )}
                <MessageSquare 
                  className={`h-8 w-8 mr-8 ${isMessagePopupOpen ? 'text-gray-600' : 'text-themeColorPrimary'} hover:opacity-80`}
                />
              </div>

              {isMessagePopupOpen && (
                <div 
                  ref={messagePopupRef}
                  className="absolute top-10 right-6 w-[350px] bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden"
                >
                  <div className="flex items-center justify-between p-3 border-b border-gray-200 bg-gray-50">
                    <h3 className="font-semibold text-gray-700">Messages</h3>
                    <X 
                      className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
                      onClick={() => setIsMessagePopupOpen(false)}
                    />
                  </div>
                  
                  <div className="flex border-b border-gray-200">
                    <button
                      className={`flex-1 py-2 px-4 text-sm font-medium ${
                        activeTab === 'unread'
                          ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                      onClick={() => setActiveTab('unread')}
                    >
                      Unread ({unreadMessages.length})
                    </button>
                    <button
                      className={`flex-1 py-2 px-4 text-sm font-medium ${
                        activeTab === 'read'
                          ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                      onClick={() => setActiveTab('read')}
                    >
                      Read ({readMessages.length})
                    </button>
                  </div>

                  <MessageList messages={activeTab === 'unread' ? unreadMessages : readMessages} />
                </div>
              )}
            </div>
            <button 
              onClick={handlePortal} 
              className="bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit ml-auto my-auto rounded"
            >
              Portal
            </button>
            <button 
              onClick={handleCreateDeal} 
              className="ml-2 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit rounded"
            >
              Create Deal
            </button>
            <button 
              onClick={handleLogout} 
              className="ml-2 mt-2 md:mt-0 lg:mt-0 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit rounded"
            >
              Logout
            </button>
          </div>
        )}
        {location === '/admin' && (
          <div className="mt-4 md:mt-0 lg:mt-0 flex md:ml-auto lg:ml-auto items-center md:items-center ld:items-center flex-col md:flex-row lg:flex-row">
            <button 
              onClick={handleLogout} 
              className="ml-2 mt-2 md:mt-0 lg:mt-0 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit rounded"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;