import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import { FiDownload } from 'react-icons/fi';
import { fetchUserInfo } from '../utils/helpers';

const AccountDetailModal = ({ user, selectedAccount, onClose }) => {
  // eslint-disable-next-line
  const [accountUser, setAccountUser] = useState(null);

  useEffect(() => {
    fetchUserInfo(user.uid)
      .then((userObject) => {
        setAccountUser(userObject);
      });
      // eslint-disable-next-line
  }, [selectedAccount]);

  return (
		selectedAccount !== null &&
    <>
      <Modal onClose={onClose}>
        <div className="mt-3 text-left">
          <h2 className="text-xl font-semibold mb-4">{selectedAccount.companyName}</h2>

          <section className="mb-4">
            <h3 className="text-lg underline font-bold mb-2">Company Information:</h3>
            <p><b>Company Name:</b> {selectedAccount.companyName}</p>
            <p><b>DBA:</b> {selectedAccount.dba || 'N/A'}</p>
            <p><b>Primary Contact:</b> {selectedAccount.primaryContactPerson}</p>
            <p><b>Email:</b> {selectedAccount.contactEmail}</p>
            <p><b>Telephone:</b> {selectedAccount.telephone}</p>
            <p><b>Office Number:</b> {selectedAccount.officeNumber}</p>
            <p><b>Address:</b> {selectedAccount.address}, {selectedAccount.city}, {selectedAccount.state} {selectedAccount.zip}</p>
            <p><b>EIN:</b> {selectedAccount.ein}</p>
            <p><b>Created At:</b> {new Date(selectedAccount.createdAt.seconds * 1000).toLocaleDateString()}</p>
          </section>

          <section className="mb-4">
            <h3 className="text-lg underline font-bold mb-2">Documents</h3>
            <ul>
              {
                selectedAccount.driversLicense &&
                <li>
                  <a href={selectedAccount.driversLicense} download target='_blank' rel='noreferrer' className='flex items-center font-semibold'>
                    <FiDownload className="mr-2" />Driver's License
                  </a>
                </li>
              }
              {
                selectedAccount.w9Form &&
                <li>
                  <a href={selectedAccount.w9Form} download target='_blank' rel='noreferrer' className='flex items-center font-semibold'>
                    <FiDownload className="mr-2" />W9 Form
                  </a>
                </li>
              }
            </ul>
          </section>
        </div>
      </Modal>
    </>
  );
};

export default AccountDetailModal;
