import React, { useEffect, useState } from 'react'
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import moment from 'moment';
import Loading from '../components/Loading';

const AdminPage = ({
  user,
  selectedDeal,
  setSelectedDeal,
  selectedAccount,
  setSelectedAccount,
  setDetailModalOpen,
  setAccountDetailsModalOpen,
}) => {
  const [deals, setDeals] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [activeTab, setActiveTab] = useState('deals');
  // eslint-disable-next-line
  const [accountMap, setAccountMap] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // Fetch accounts first
      const accountsQuery = query(collection(db, "users"), orderBy('createdAt', 'asc'));
      const accountsSnapshot = await getDocs(accountsQuery);

      const accountsData = accountsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Create a mapping of user ID to company name
      const userCompanyMap = {};
      accountsData.forEach(account => {
        userCompanyMap[account.userId] = account.companyName;
      });

      setAccountMap(userCompanyMap);
      setAccounts(accountsData);

      // Then fetch and process deals
      const dealsQuery = query(collection(db, "deals"), orderBy('dateSubmitted', 'asc'));
      const dealsSnapshot = await getDocs(dealsQuery);

      const dealsData = dealsSnapshot.docs.reverse().map(doc => {
        const dealData = doc.data();
        return {
          id: doc.id,
          ...dealData,
          // Add the company name using the mapping
          companyName: userCompanyMap[dealData.userId] || 'Unknown Company'
        };
      });

      setDeals(dealsData);
      setFetching(false);
    };

    fetchData();
  }, [user, selectedDeal, selectedAccount]);

  const handleViewDeal = (deal) => {
    window.location = `/deal/modify/${deal.id}`
    setSelectedDeal(deal);
    setDetailModalOpen(true);
  };

  const handleViewAccount = (account) => {
    setSelectedAccount(account);
    setAccountDetailsModalOpen(true);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
      <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>
        {/* Tab Navigation */}
        <div className="flex justify-center mb-6">
          <button
            onClick={() => handleTabChange('deals')}
            className={`px-4 py-2 text-lg font-bold ${activeTab === 'deals' ? 'border-b-4 border-[#078586]' : ''}`}
          >
            Submissions
          </button>
          <button
            onClick={() => handleTabChange('accounts')}
            className={`px-4 py-2 text-lg font-bold ml-4 ${activeTab === 'accounts' ? 'border-b-4 border-[#078586]' : ''}`}
          >
            ISO
          </button>
        </div>

        {
          !fetching ?
            <>
            {/* Deals Tab */}
            {activeTab === 'deals' && (
              <div>
                <h1 className="text-4xl font-bold my-4 mb-[30px]">All Deals</h1>
                {deals.length > 0 ? (
                  <table className="w-full px-6">
                    <thead>
                      <tr className="text-center text-xl">
                        <th className="pb-4">Date</th>
                        <th className="pb-4">Business Name</th>
                        <th className="pb-4">ISO</th>
                        <th className="pb-4">ISO Referrer</th>
                        <th className="pb-4">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deals.map((deal) => (
                        <tr
                          key={deal.id}
                          className="mb-4 shadow-lg rounded-2xl border w-full cursor-pointer hover:shadow-xl hover:border-themeColorPrimary transition-all"
                        >
                          <td className="font-semibold text-base text-center mb-2 py-4">
                            {moment(deal.dateSubmitted.toDate()).format('MM - DD - YYYY')}
                          </td>
                          <td className="font-semibold text-base text-center mb-2 py-4 underline" onClick={() => handleViewDeal(deal)}>
                            {deal.businessName}
                          </td>
                          <td className="font-semibold text-base text-center mb-2 py-4">
                            {deal.companyName}
                          </td>
                          <td className="text-base text-center mb-2 py-4 cursor-pointer font-bold" >
                            {deal.referrer}
                          </td>
                          <td className="font-semibold text-base text-center mb-2 py-4">
                            {deal.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  !fetching && (
                    <div>
                      <table className="w-full px-6">
                        <thead>
                          <tr className="text-center text-xl">
                            <th className="pb-4">Date Submitted</th>
                            <th className="pb-4">Business Name</th>
                            <th className="pb-4">ISO</th>
                            <th className="pb-4">ISO Referrer</th>
                            <th className="pb-4">Status</th>
                          </tr>
                        </thead>
                      </table>
                      <p className="w-full text-center mt-4 text-2xl font-bold text-red-600">No deals found!</p>
                    </div>
                  )
                )}
              </div>
            )}

            {/* Accounts Tab remains the same */}
            {activeTab === 'accounts' && (
              <div>
                <h1 className="text-4xl font-bold my-4 mb-[30px]">All ISO</h1>
                {accounts.length > 0 ? (
                  <table className="w-full px-6">
                    <thead>
                      <tr className="text-center text-xl">
                        <th className="pb-4">ISO Name</th>
                        <th className="pb-4">ISO Phone Number</th>
                        <th className="pb-4">ISO Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts.map((account) => (
                        <tr key={account.id} className="mb-4 shadow-lg rounded-2xl border w-full">
                          <td className="font-semibold text-base text-center mb-2 py-4 underline cursor-pointer" 
                              onClick={() => handleViewAccount(account)}>
                            {account.companyName}
                          </td>
                          <td className="font-semibold text-base text-center mb-2 py-4">
                            {account.telephone}
                          </td>
                          <td className="font-semibold text-base text-center mb-2 py-4">
                            {account.userId}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  !fetching && (
                    <p className="w-full text-center mt-4 text-2xl font-bold text-red-600">No accounts found!</p>
                  )
                )}
              </div>
            )}
          </> :
          <div className="h-[300px] overflow-hidden">
            <Loading theme="primary" />
          </div>
        }

      </div>
    </div>
  );
};

export default AdminPage;